import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Typography} from '@mui/material';

export const ForceMenu = _ => {
  const {product_name, type, price} = useParams(),
    [admin_number] = useState('628117198908');

  useEffect(() => {
    if(type && product_name && price) {
      if(type === 'kritik' && price === 'saran') {
        window.location.replace(`https://wa.me/628117893007?text=${encodeURIComponent(`Hi cece,
Saya mau kasih Kritik & Saran:

`)}`)
      }else {
        window.location.replace(`https://wa.me/${admin_number}?text=${encodeURIComponent(`Hi Admin,
Saya mau Order Kue ${type === 'p' ? 'Promo': ''}:
${product_name.replace(/\./g, ' ')} - ${price.length < 3 ? `${price}rb` : price}
`)}`)

      }
    }
  }, [product_name, type, price, admin_number])

  return (
    <div style={{width: '100%', height: '90vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {/* <CircularProgress/> */}
      <img style={{width: '100%', height: 'auto'}} src='/images/loading.gif' alt='molasweetjar'/>

      <Typography variant='h6'>
        Mohon ditunggu ya...
      </Typography>
    </div>
  )
}