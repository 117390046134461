
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Button,
  IconButton,
} from '@mui/material'
import {HelpOutlined, LocationOnOutlined, WebAssetOutlined, FavoriteOutlined, WhatsappOutlined, Instagram, FeedbackOutlined} from '@mui/icons-material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {BasicModal} from '../components/modal';
import {Helmet} from 'react-helmet';
import './home.scss';

export const HomePage = () => {
  const [showHelp, setShowHelp] = useState(false),
    [phoneWaBusinnes] = useState('628117198908'),
    [phoneBoss] = useState('628117893007'),
    push = useNavigate();

  // useEffect(() => {
  //   var myIndex = 0;
  //   carousel();

  //   function carousel() {
  //     var i;
  //     var x = document.getElementsByClassName("imageSlide");
  //     for (i = 0; i < x.length; i++) {
  //       x[i].style.display = "none";  
  //     }
  //     myIndex++;
  //     if (myIndex > x.length) {myIndex = 1}    
  //     x[myIndex-1].style.display = "block";  
  //     setTimeout(carousel, 9000);    
  //   }
  // }, [])

  const onClickItem = (link, self) => _ => {
    if(self) {
      push(link);
      return;
    }
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
  }
  return (
    <div className='home-page-container' style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 15}}>
      <Helmet>
        <meta charSet='utf-8' name='description' content='Jajanan Palembang'/>
        <link rel='canonical' href='https://molasweetjar.com'/>
      </Helmet>
      {showHelp
        &&  <BasicModal
              open
              close={setShowHelp}>
              <video height='500px' poster="/images/molas_logo.png" controls>
                <source src="/videos/tutor_whatsapp_catalogue.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </BasicModal>}
      {/* <Example/> */}
      {/* <Typography variant='h5'>Fresh Baking Every Day</Typography>
      <Typography variant='body1'>Tanpa PENGAWET/SP/Ovalet/Pengembang</Typography>
      <Typography variant='caption'>
        <b>{status.open ? 'OPEN' : 'CLOSED'}</b>&nbsp;{status.hours}
      </Typography>
      <div style={{maxHeight: 500, marginTop: 10, marginBottom: 10}}>
        {
          [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17].map( (item, i) =>  <img className='imageSlide fading' src={`/images/homepage/preview_${item}.png`} alt={`item-${item}`}/> )
        }

      </div> */}
      <div className='body-container'>
        <img alt='base-bolu' src='https://storage.googleapis.com/dlink-project/Untitled_Artwork.PNG'/>
        
        <div className='body-container--description'>
          <Helmet>
            <meta content='Kue Ulang tahun start from 165k' name='description'/>
          </Helmet>
          <Typography style={{fontWeight: 'bolder'}}>Order Today</Typography>
          <Typography style={{fontWeight: 'bolder'}}>Baking Today!!</Typography>
          <Typography style={{fontWeight: 'bolder'}}>Freshly homemade Cake</Typography>

          <Typography variant='caption' textAlign='center'>
            Senin: 08.30 - 16.00<br/>
            Selasa-Sabtu: 08.00 - 17.00<br/>
            Minggu: <b>TUTUP</b>
          </Typography>

          <Button onClick={onClickItem(`https://wa.me/c/${phoneWaBusinnes}`, false)} variant='outlined' size='small' className='more-info'>Menu</Button>
        </div>
      </div>

      <List style={{width: '100%'}}>
        {[
          // {
          //   key: 0.5,
          //   primary: 'Idul Adha',
          //   secondary: 'Pesan Idul Adha 9 July 2022',
          //   url: '/event/adha',
          //   leftIcon: <EventOutlined/>
          // },
          {
            key: 1,
            primary: 'Best Seller',
            secondary: 'Start from 70K - 160K',
            href: `https://wa.me/p/4628147563953769/${phoneWaBusinnes}`,
            leftIcon: <FavoriteOutlined/>,
            helmet: <Helmet>
            <meta content='Jajan kue palembang, Bolu jadul, Bolu Gulung, Roti Sisir' name='description'/>
          </Helmet>
          },
          // {
          //   key: 2,
          //   primary: 'Menu',
          //   secondary: 'Masukan ke keranjang kemudian chat ke whatsapp bisnis kami ❤️',
          //   href: `https://wa.me/c/${phoneWaBusinnes}`,
          //   help: _ => setShowHelp(true),
          //   leftIcon: <RestaurantMenuOutlined/>,
          //   helmet: <Helmet>
          //     <title>Menu Mola's Sweet Jar</title>
          //     <meta content='Kue Ulang tahun start from 165k' name='description'/>
          //   </Helmet>
          // },
          // {
          //   key: 2,
          //   primary: 'Menu',
          //   secondary: 'Semua Kue / Roti Tanpa Bahan Pengawet atau Obat2an',
          //   // href: `https://wa.me/c/${phoneWaBusinnes}`,
          //   url: '/menu',
          //   // help: _ => setShowHelp(true),
          //   leftIcon: <RestaurantMenuOutlined/>
          // },
          {
            key: 3,
            primary: 'Whatsapp',
            secondary: 'Kirim pesan',
            href: `https://wa.me/${phoneWaBusinnes}?text=${encodeURIComponent(`Hallo min, saya mau Order:
Tanggal: 
Nama Pemesan: 
Pesanan: `)}`,
            leftIcon: <WhatsappOutlined/>
          },
          {
            key: 3.5,
            primary: 'Format Kurir',
            secondary: 'Kirim pesan',
            href: `https://wa.me/${phoneWaBusinnes}?text=${encodeURIComponent(`!kurir`)}`,
            leftIcon: <WhatsappOutlined/>
          },
          {
            key: 4,
            primary: 'Instagram',
            secondary: 'Official Instagram Account',
            href: `https://instagram.com/molasweetjar`,
            leftIcon: <Instagram/>
          },
          {
            key: 5,
            primary: 'Complaint / Feedback',
            secondary: 'Kritik / Masukkan agar kami bisa menjadi yang lebih baik lagi',
            href: `https://wa.me/${phoneBoss}?text=Hallo ce molas, saya mau Complaint / Feedback nih`,
            leftIcon: <FeedbackOutlined/>
          },
          {
            key: 6,
            primary: 'Location',
            secondary: 'Jl.Sumpah Pemuda no.7B',
            href: 'https://www.google.com/maps/place/Molasweetjar/@-2.9759895,104.7371935,20.31z/data=!4m5!3m4!1s0x2e3b75d94bc579ed:0x42a095b8a4ff974a!8m2!3d-2.9758431!4d104.737142',
            leftIcon: <LocationOnOutlined/>
          },

          {
            key: 7,
            primary: 'Web Developer',
            secondary: 'Free konsul pembuatan Website / Applikasi berbasis website or mobile apps',
            href: 'https://wa.me/6289604884717?text=Hallo min, mau buat website / applikasi website nih',
            leftIcon: <WebAssetOutlined/>
          }
        ].map(data => (
          <ListItem style={{border: '1px solid #F69D90', borderRadius: 5, marginTop: 10}} key={data.key}>
            {data.helmet
              && data.helmet}
            {data.leftIcon
              ? <ListItemIcon>
                  {data.leftIcon}
                </ListItemIcon> : null}
            <ListItemButton onClick={onClickItem(data.href || data.url, data.url ? true : false)}>
              <ListItemText
                primaryTypographyProps={{fontSize: 18, fontWeight: 'bold', color: '#F69D90'}}
                primary={data.primary}
                secondary={data.secondary}/>
            </ListItemButton>
            {data.help !== undefined
              ? <ListItemIcon>
                  <IconButton
                    onClick={data.help}>
                    <HelpOutlined/>

                  </IconButton>
                </ListItemIcon>
              : null}
          </ListItem>
        ))}
      </List>

    </div>
  )
}