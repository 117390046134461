import {BrowserRouter as Router, Route, Routes, Outlet} from 'react-router-dom';
import {Container, Typography} from '@mui/material';
import {Navbar} from './navbar';
import {HomePage} from './containers/homepage';
// import {Event} from './containers/event/idul_adha';
import {ForceMenu} from './containers/menu/promo';

const Layout = () => (
  <Container>
    <Navbar/>
    <div style={{height: '6vh'}}/>
    <Outlet/>
    <div style={{height: '6vh'}}/>
  </Container>
)

const NotFound = () => (
  <Container>
    <Navbar/>
    <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant='h5'>Link tidak valid</Typography>

    </div>
  </Container>
)
export const App = () => {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route path='' element={<HomePage/>}/>
          {/* <Route exact path='event/adha' element={<Event/>}/> */}
          <Route path=':type/:product_name/:price' element={<ForceMenu/>}/>
        </Route>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </Router>
  )
}
