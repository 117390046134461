import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const BasicModal = ({children, open, close}) => (
  <Modal
    open={open}
    onClose={_ => close(false)}
  >
    <Box sx={style}>
      {children}
    </Box>
  </Modal>
);
